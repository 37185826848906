import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-allen-texas.png'
import image0 from "../../images/cities/scale-model-of-downtown-plano-in-allen-texas.png"
import image1 from "../../images/cities/scale-model-of-heritage-farmstead-museum-in-allen-texas.png"
import image2 from "../../images/cities/scale-model-of-heard-natural-science-museum-&-wildlife-sanctuary-in-allen-texas.png"
import image3 from "../../images/cities/scale-model-of-celebration-park-in-allen-texas.png"
import image4 from "../../images/cities/scale-model-of-dallas-horseshoeing-service-in-allen-texas.png"
import image5 from "../../images/cities/scale-model-of-all-world-travel-&-tours-in-allen-texas.png"
import image6 from "../../images/cities/scale-model-of-allen-convention-&-visitors-bureau-in-allen-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='Allen'
            state='Texas'
            image={image}
            lat='33.0997'
            lon='-96.6631'
            attractions={ [{"name": "Downtown Plano", "vicinity": "1037 E 15th St, Plano", "types": ["point_of_interest", "establishment"], "lat": 33.0198482, "lng": -96.69954999999999}, {"name": "Heritage Farmstead Museum", "vicinity": "1900 W 15th St, Plano", "types": ["museum", "point_of_interest", "establishment"], "lat": 33.018865, "lng": -96.731065}, {"name": "Heard Natural Science Museum & Wildlife Sanctuary", "vicinity": "1 Nature Pl, McKinney", "types": ["museum", "park", "school", "point_of_interest", "establishment"], "lat": 33.158261, "lng": -96.61574100000001}, {"name": "Celebration Park", "vicinity": "701 Angel Pkwy, Allen", "types": ["park", "point_of_interest", "establishment"], "lat": 33.1075896, "lng": -96.62563539999996}, {"name": "Dallas Horseshoeing Service", "vicinity": "631 Huntington Ln, Allen", "types": ["veterinary_care", "travel_agency", "point_of_interest", "establishment"], "lat": 33.0901992, "lng": -96.6448962}, {"name": "All World Travel & Tours", "vicinity": "541 W McDermott Dr, Allen", "types": ["travel_agency", "point_of_interest", "establishment"], "lat": 33.100389, "lng": -96.67596400000002}, {"name": "Allen Convention & Visitors Bureau", "vicinity": "301 Century Pkwy, Allen", "types": ["point_of_interest", "local_government_office", "establishment"], "lat": 33.1012587, "lng": -96.6739488}] }
            attractionImages={ {"Downtown Plano":image0,"Heritage Farmstead Museum":image1,"Heard Natural Science Museum & Wildlife Sanctuary":image2,"Celebration Park":image3,"Dallas Horseshoeing Service":image4,"All World Travel & Tours":image5,"Allen Convention & Visitors Bureau":image6,} }
       />);
  }
}